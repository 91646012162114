.menu ul{
   list-style:none;
  display: inline-block;
}

.menu{
  display: inline-block;
  font-size:18px;
  cursor: pointer;
  background-color: #2874f0;
  color: #ffffff;
}

.menu ul li{
  display: inline-block;
  padding: 10px 20px;
  cursor:pointer;
  background-color:#2874f0;
  color:#ffffff;
  border-radius: 10px;
  transition:0.3s;
}

.menu ul li:hover{
  background-color:#54b3fd;
  border-radius: 10px;
}


.styleCard {
    border-radius: 8px;
    box-shadow: 0px 0px 8px #ccc;
    color: #253b56;
    margin: 12px 0px;
    border : solid 1px #fadc00;
    padding-top: 10px;
    }

  .styleCard:hover {   
    box-shadow: 8px 8px 8px  #8294aa;
    transform: translateY(-10px);
  }
    
  .styleCardContent {
    padding: 4px 16px 0px 16px;
  }
  
  .styleimagediv{
    background-color: lightGray;
    border-radius: 8px 8px 0 0;
    margin-left: 25%;
    height: 140px;
    width: 60%;
    overflow: hidden;
  }
  .styleImage {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  
  .styleCardTitle {
    font-family: Avenir-Heavy;
    font-size: 20;
    font-weight: 600;
    margin: 12px 0px 0px 0px;
    text-align: center;
  }
  
  .styleSellingpriceLabel {
    color: #099c2e;
    font-size: 14;
    margin: 4px 0;
  }

  .styleOfferLabel {
    color: #000103;
    font-size: 12;
    font-weight: 300;
    margin: 4px 0;
  }
  .styleMrpLabel {
    color: #8294aa;
    font-size: 13;
    margin: 4px 0;
    text-decoration: line-through;
  }
  .action{
    text-align: right;
    padding: 15px;   
  }
 
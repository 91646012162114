.productimg {
    height: 100%;
    width: 100%;
    object-fit: contain;
   }
   .productdiv {
    border-radius: 8px;
    box-shadow: 0px 0px 8px #ccc;
    margin: 12px 0px;
    border : solid 1px #fadc00;
    padding-top: 10px;
    height:500px;
    margin: auto;
    width: 100%;
   }
   
   .productPrice {
    display: inline-block;
    font-weight: 500;
    color: #087a24;
    margin: 15px 25px;
    font-size: 30px;
    font-family: system-ui;
  }

  .productOffer {
    font-size: 17px;
    font-weight: 400;
    margin: 0px 35px;
    background-color: yellow;
    color: blue;
    font-weight: 500;
    width: fit-content;
    padding: 5px;
     }
  .productMrp {
    color: #8294aa;
    font-size: 20px;
    margin: 1px 25px;
    text-decoration: line-through;
  }
  .productDescrip {
    color: #000000;
    font-size: 20px;
    margin: 4px 0px;
    text-align: justify;
     padding: 21px 20px;
  }

  .productoffdiv {
    padding: 10px;
  }
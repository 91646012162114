.logo {
    height: 121px;
    /* margin-left: 94px;   
    position: absolute; */
    margin: 15px 40px;
    top: 17px;
    /* left: -25px; */
   width: 120px;
   border: 3px solid #fadc00;
   box-shadow: 4px 4px grey;
}
.logo img{
    filter:brightness(20);
}
.header {
    height: 150px;
    border-bottom-right-radius: 115px;
    /* border-top-left-radius: 90px; */
    box-shadow: 5px 5px #fadc00;
    background-color: #2874f0;
}
.title {
    color: #2874f0;
    background-color:rgb(250 220 45);
    font-weight: bold;
    padding-left: 2px;
}





.sidebar {
    background: #ffffff;
    height: 70vh;
    width: 75%;
    /* position: absolute; */
    transition: all 0.25s ease;
    opacity:1;
    visibility: visible;
    transform: translateX(0);
    overflow: hidden;
  }
  .sidebar a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: block;
    padding: 20px;
  }
  .sidebar ul {
    padding: 0;
    margin: 0;
  }
  .sidebar ul li {
    font-size: 16px;
    border-bottom: 1px solid rgb(0, 0, 0);
    transition: all 0.25s ease;
    animation: fadeInRight 0.25s ease forwards;
    opacity: 1;
  }
  .sidebar ul li:hover {
    opacity: 0.8;
    transition: all 0.25s ease;
   
    cursor: pointer;
  }
  .ad{
    width: 250px;
    margin: 4px 0;
  }
  
  .sidebar ul li:first-child {
    font-size: 18px;
    font-weight:bold;
    padding: 20px;
    background-color: rgb(250 220 45);
  }
  
  .itemTitle {
    font-family: Avenir-Heavy;
    font-size: 25px;
    font-weight: 800;
    margin: 12px 0px 0px 0px;
  }
  
  
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loadingspinner {
    width: 80px;
    height: 80px;
    margin: 70%;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #2874f0; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }